import ButtonBase from "@material-ui/core/ButtonBase"
import Typography from "@material-ui/core/Typography"
import React from "react"
import { animated } from "react-spring"
import styled from "styled-components"
import { getLinkProps } from "../../../helpers"
import useBoop from "../../../hooks/useBoop"
import { shadow, simpleTransition } from "../../../theme/globalStyles"
import { KaartFragment } from "../../../__generated__/graphql-gatsby"
import { KaartFragment as PreviewKaartFragment } from "../../../__generated__/graphql-wp-sdk"
import Img from "./img"

interface IProps {
  block: KaartFragment | PreviewKaartFragment | null
}

const Wrapper = styled(ButtonBase)`
  display: block;
  width: 100%;
  max-width: 320px;
  margin: ${({ theme }) => theme.spacing(4)}px;
  ${shadow}

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    ${simpleTransition};
  }

  :hover {
    .overlay {
      background-color: rgba(0, 0, 0, 0.28);
    }
  }
`

const ImgWrapper = styled.div`
  height: 280px;
  position: relative;
`

interface TitleProps {
  bg: Maybe<String>
}
const Title = styled(({ ...props }) => <Typography component="div" {...props} />)<TitleProps>`
  text-align: center;
  padding: ${({ theme }) => theme.spacing(2)}px;
  font-weight: bold;
  position: relative;

  background-color: ${({ theme, bg }) => {
    switch (bg) {
      case "purple":
        return theme.palette.secondary.main
      case "blue":
        return theme.palette.primary.main
      case "yellow":
      default:
        return theme.palette.tertiary.main
    }
  }};
  color: ${({ theme, bg }) => {
    switch (bg) {
      case "purple":
        return theme.palette.secondary.contrastText
      case "blue":
        return theme.palette.primary.contrastText
      case "yellow":
      default:
        return theme.palette.tertiary.contrastText
    }
  }};
`

const Card: React.FC<IProps> = ({ block }) => {
  const [style, trigger] = useBoop({ sy: 0.6 })

  const url = String(
    block?.type === "pagina" && block?.linkPagina && "uri" in block.linkPagina
      ? block?.linkPagina?.uri
      : block?.linkCategorie?.uri
  )
  return (
    <Wrapper {...getLinkProps(url)} focusRipple onMouseEnter={trigger}>
      <ImgWrapper>
        {block?.img?.__typename === "WpMediaItem" && (
          <Img
            img={block?.img?.localFile?.childImageSharp?.gatsbyImageData}
            alt={block?.img?.altText}
          />
        )}
        {block?.img?.__typename === "MediaItem" && (
          <img
            src={String(block?.img?.mediaItemUrl)}
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
            alt="preview"
          />
        )}
        <div className="overlay" />
      </ImgWrapper>
      <Title className="title" variant="h6" bg={block?.color}>
        <animated.div style={style}>{block?.titel}</animated.div>
        <div className="overlay" />
      </Title>
    </Wrapper>
  )
}

export default Card

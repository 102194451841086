import React from "react"
import styled, { css } from "styled-components"
import { smMediaQuery } from "../../../theme/globalStyles"
import { KaartenFragment } from "../../../__generated__/graphql-gatsby"
import { KaartenFragment as PreviewKaartenFragment } from "../../../__generated__/graphql-wp-sdk"
import Card from "./card"

interface IProps {
  blocks: KaartenFragment | PreviewKaartenFragment
  padding: Maybe<Maybe<string>[]> | undefined
}

interface WrapperProps {
  paddingTop: boolean | undefined
  paddingBottom: boolean | undefined
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  background-color: ${({ theme }) => theme.palette.background.default};

  padding-top: ${({ paddingTop, theme }) => theme.spacing(paddingTop ? 4 : 0)}px;
  padding-bottom: ${({ paddingBottom, theme }) => theme.spacing(paddingBottom ? 4 : 0)}px;
  margin-top: ${({ paddingTop, theme }) => theme.spacing(paddingTop ? 7 : 0)}px;
  margin-bottom: ${({ paddingBottom, theme }) => theme.spacing(paddingBottom ? 8 : 0)}px;
  margin-left: auto;
  margin-right: auto;

  max-width: ${({ theme }) => theme.breakpoints.values.lg}px;

  ${smMediaQuery(css`
    flex-direction: column;
  `)}
`

const Cards: React.FC<IProps> = ({ padding, blocks }) => (
  <Wrapper paddingTop={padding?.includes("top")} paddingBottom={padding?.includes("bottom")}>
    {blocks.list?.map((block, i) => (
      <Card key={`cards-${i}`} block={block} />
    ))}
  </Wrapper>
)

export default Cards

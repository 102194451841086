import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

interface IProps {
  img?: any
  alt?: Maybe<string>
}

const Img: React.FC<IProps> = ({ img, alt }) => (
  <GatsbyImage image={img} style={{ height: "100%" }} objectFit="cover" alt={String(alt)} />
)

export default Img
